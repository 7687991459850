import React, { Component } from 'react';
// const Spinner = require('react-spinkit');
import Loading from './Loading';

export default class LoadingIndicator extends Component {
  render() {
    let style = { clear: 'both' };
    let overlay = {};
    if (this.props.overlay) {
      style = {
        position: 'fixed',
        left: 'calc(50% - 25px)',
        width: 50,
        height: 50,
        top: 'calc(50% - 25px)',
      };
      overlay = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      };
    }
    return (
      <div style={overlay}>
        <Loading style={style} />
      </div>
    );
  }
}
