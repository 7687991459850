import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import { isIOS, isAndroid } from 'mobile-device-detect';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import safeAreaInsets from 'safe-area-insets';
import Icon from '../Icon';
import './style.scss';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;

function ModalView(props) {
  // useEffect(() => {
  //   if (props.hideGlobalSearchBar) {
  //     jQuery('#mobile-search-bar').hide();
  //   }
  //   return () => {
  //     if (props.hideGlobalSearchBar) {
  //       jQuery('#mobile-search-bar').show();
  //     }
  //   };
  // }, [props.hideGlobalSearchBar]);

  // // FIXME This is what was causing the back button issue in BXPR-304
  // useEffect(() => {
  //   if (window.history.pushState) {
  //     const current = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`;
  //     const newurl = props.open ? `${current}#show_modal` : current;
  //     window.history.pushState({ path: newurl }, '', newurl);
  //   }
  // }, [props.open]);
  //
  // window.onhashchange = () => {
  //   console.log('User clicked physical back button...');
  //   if (typeof props.onClose === 'function') {
  //     console.log('Closing modal...');
  //     // FIXME Why won't this close??? Can't even set a forceClose state var to force it shut
  //     props.onClose();
  //   }
  // };

  // https://gist.github.com/schjetne/5451674
  const goBack = () => {
    if (document.referrer.indexOf(window.location.host) !== -1) {
    // if (document.referrer !== '' || document.referrer.indexOf('boxpressd') !== -1) {
      window.history.back();
    } else {
      props.history.replace('/');
    }
  };

  const backArrow = () => {
    if (isAndroid) {
      return (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Go Back"
          onClick={props.onClose || goBack}
        >
          <Icon
            name="arrow-left"
            size={22}
            style={{ height: 22, width: 22 }}
          />
        </IconButton>
      );
    }
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Go Back"
        onClick={props.onClose || goBack}
      >
        <Icon
          name="chevron-left"
          size={20}
          style={{ height: 22, width: 22 }}
        />
        {/* <span style={{ fontSize: 14 }}>Back</span> */}
      </IconButton>
    );
  };

  const renderTitle = () => {
    const { title } = props;
    return (
      <Typography
        // className={classes.title}
        style={{ flexGrow: 1, fontWeight: 600 }}
        variant="span"
        noWrap
      >
        { title || '' }
      </Typography>
    );
  };

  const renderRightButtons = () => {
    const { rightButtons } = props;
    if (rightButtons) {
      // console.log('Rendering right buttons...');
      // TODO Can we pass aria-label in RightButton and use it here? RightButton.props.ariaLabel
      return rightButtons.map((button, index) => (
        <IconButton
          key={`modal-btn-${index}`}
          aria-label=""
          edge="end"
          color="inherit"
          style={{ maxWidth: 50 }}
        >
          { button }
        </IconButton>
      ));
    }
    return null;
  };

  const renderChildren = () => {
    const { children, hideScroll, path } = props;
    const insetTop = safeAreaInsets.top;
    const insetBottom = safeAreaInsets.bottom;
    return (
      <div
        role="presentation"
        // onClick={onClick}
        // onKeyDown={onClick}
        style={{
          zIndex: 1,
          height: '100vh',
          // flex: 1,
          width: '100vw',
          paddingTop: path ? 0 : `${56 + insetTop}px`,
          overflow: hideScroll ? 'hidden' : 'visible',
          paddingBottom: insetBottom,
          backgroundColor: props.style.backgroundColor || 'inherit',
        }}
      >
        {children}
      </div>
    );
  };

  const renderInner = () => {
    // FIXME This is disabling the scroll in this view, not the background div
    // if (!props.enableBodyScroll) {
    //   return (
    //     <>
    //       <ScrollLock isActive={isIOS && !props.enableBodyScroll && props.open} />
    //       {renderChildren()}
    //     </>
    //   );
    // }
    return renderChildren();
  };

  const renderContent = () => {
    const styles = { sticky: 0 };
    const insetTop = safeAreaInsets.top;

    if (props.hideAppBarShadow) {
      styles.boxShadow = 'none';
    }
    if (window.Capacitor.platform === 'ios' || (isIOS && isInStandaloneMode)) {
      styles.height = `${56 + insetTop}px`;
      styles.paddingTop = insetTop;
    }
    return (
      <div style={{ display: 'flex', overflow: props.hideScroll ? 'hidden' : 'visible' }}>
        <AppBar
          // position="static"
          color="white"
          style={styles}
        >
          <Toolbar>
            { backArrow() }
            { renderTitle() }
            { renderRightButtons() }
          </Toolbar>
        </AppBar>
        {renderInner()}
      </div>
    );
  };

  const renderDrawer = (permanent = true) => {
    const {
      showFrom,
      onClose,
      open,
    } = props;

    return (
      <Drawer
        id={props.id || 'boxpressd-modal-view'}
        className={props.id || 'boxpressd-modal-view'}
        anchor={showFrom}
        open={open}
        variant={permanent ? 'permanent' : 'temporary'}
        // onClick={onClick}
        onRequestClose={onClose}
      >
        {renderContent()}
      </Drawer>
    );
  };

  const { path } = props;

  // FIXME Check out https://tylermcginnis.com/react-router-animated-transitions/ for a possible better solution that
  //  uses a custom CSS transition, which we can change depending on the anchor (bottom or right)
  // open = (window.location.pathname === path) || open;

  if (path) {
    return renderContent();
  }
  return renderDrawer(false);
}

ModalView.propTypes = {
  open: PropTypes.bool,
  showFrom: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  hideAppBarShadow: PropTypes.bool,
  enableBodyScroll: PropTypes.bool, // It's disabled by default - we don't want the body scrolling behind the modal
  style: PropTypes.object,
};

ModalView.defaultProps = {
  style: {},
};

export default withRouter(ModalView);
