import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/StaticDatePicker';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';

export const mobileDatePickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#d6c290',
    },
    secondary: {
      main: '#d6c290',
    },
  },
  overrides: {
    MuiInput: {
      root: {
        display: 'none',
      },
      input: {
        display: 'none',
      },
    },
    MuiPickersDay: {
      dayDisabled: {
        color: '#cccccc',
      },
      current: {
        color: '#5c5c5c',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#5c5c5c',
      },
    },
  },
});

export const defaultDatePickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#d6c290',
    },
    secondary: {
      main: '#d6c290',
    },
  },
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        color: '#cccccc',
      },
      current: {
        color: '#5c5c5c',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#5c5c5c',
      },
    },
  },
});

function MobileDatePicker(props) {
  // FIXME iOS does a good job showing the default date picker with a simple input - may just want to use that
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <ThemeProvider theme={props.theme || mobileDatePickerTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker {...props} renderInput={(params) => <TextField {...params} />} />
        </LocalizationProvider>
      </ThemeProvider>
    </Dialog>
  );
}

export default MobileDatePicker;
