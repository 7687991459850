import Badge from '@material-ui/core/Badge';
import React from 'react';
import { UserStore, GroupStore, LoungeStore } from '../../stores';
import Icon from '../Icon';

function MobileMenuIcon(props) {
  const friendRequestCount = UserStore.useState((s) => s.friendRequestCount);
  const groupPostCount = GroupStore.useState((s) => s.notificationCount);
  const loungeMessageCount = LoungeStore.useState((s) => s.notificationCount);
  const badgeCount = friendRequestCount + groupPostCount + loungeMessageCount;
  return (
    <button onClick={props.toggleSideMenu} type="button" style={{ border: 'none', background: 'transparent' }}>
      <Badge badgeContent={badgeCount || 0} color="error" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Icon name="menu" size={24} style={{ color: '#586069' }} />
      </Badge>
    </button>
  );
}

export default MobileMenuIcon;
