import React from 'react';
import changeCase from 'change-case';
import SvgIcon from '@material-ui/core/SvgIcon';

// FIXME This is a great helper class but we need to be able to do dynamic imports instead of importing all above
//  see https://stackoverflow.com/questions/56369444/how-can-i-dynamically-load-an-icon-using-its-snake-case-name-react-material-ui
//  as of right now, this is causing the huge bundles from the looks of things - ideally, we should probably create our
//  own bundle of icons

/**
 * Component
 *
 * @param {Object} props component props.
 * @return {Object} component.
 */
const Icon = (props) => {
  const {
    vendor = 'feather',
  } = props;

  let {
    name,
  } = props;

  let result = '';

  const opts = {
    ...props,
  };
  delete opts.vendor;

  opts.className = `${(opts.className || '')} rui-icon`;

  // Feather.
  if (vendor === 'feather') {
    const FeatherIcons = require('react-feather');
    name = changeCase.pascalCase(name);

    if (!FeatherIcons[name]) {
      name = 'AlertTriangle';
    }

    const RenderIcon = FeatherIcons[name];

    result = <RenderIcon {...opts} strokeWidth="1.5" />;
  }

  if (vendor === 'ion') {
    name = changeCase.pascalCase(name);

    let IonIcon = require(`react-ionicons/lib/${name}`);
    if (!IonIcon) {
      IonIcon = require('react-ionicons/lib/AlertOutline');
    }

    result = <span className="filled"><IonIcon {...opts} strokeWidth="1.5" /></span>;
  }

  // FontAwesome.
  if (vendor === 'fa') {
    const far = require('@fortawesome/free-regular-svg-icons');
    // const fal = require('@fortawesome/free-light-svg-icons');
    const fas = require('@fortawesome/free-solid-svg-icons');
    const fab = require('@fortawesome/free-brands-svg-icons');
    const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome');

    const faLib = {
      far,
      fas,
      fab,
      // fal,
    };

    let icon = '';
    let lib = 'far';

    if (Array.isArray(name)) {
      lib = name[0];
      name = name[1];
    }

    name = `fa${changeCase.pascalCase(name)}`;

    if (faLib[lib] && faLib[lib][name]) {
      icon = faLib[lib][name];
    }

    result = icon ? <FontAwesomeIcon {...opts} icon={icon} /> : '';
  }

  if (vendor === 'svg') {
    return <SvgIcon {...opts} component={props.component} viewBox={props.viewBox} />;
  }

  if (vendor === 'material') {
    const MaterialIcons = require('@material-ui/icons');
    name = changeCase.pascalCase(name);

    if (!MaterialIcons[name]) {
      name = 'Warning';
    }

    const RenderIcon = MaterialIcons[name];
    result = <RenderIcon {...opts} strokeWidth="1.5" />;
  }

  return result;
};

export default Icon;
