import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'mobile-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from 'react-autosuggest';
import { throttle, debounce } from 'throttle-debounce';
import Icon from '../Icon';
import { language, messages } from '../../utils/localeUtils';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Cigar from '../../models/Cigar';
import { SearchStore } from '../../stores';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    margin: '8px 20px',
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: darken(theme.palette.common.white, 0.10),
    '&:hover': {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },
    '&:focus': {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: 44,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const MainSearchBar = forwardRef((props, ref) => {
  const classes = useStyles();
  const [renderedClearButton, setRenderedClearButton] = useState(false);
  const [buttons, setButtons] = useState([]);

  const suggestions = SearchStore.useState((s) => s.cigars);
  console.log('Suggestions:');
  console.log(suggestions);

  useImperativeHandle(ref, () => ({
    clearQuery() {
      clearInput();
    },
  }));

  const [query, setQuery] = useState(null);
  const { rightButtons, onQueryChange, showClearButton, onClear } = props;

  useEffect(() => {
    if (showClearButton) {
      if (!renderedClearButton) {
        const btns = rightButtons.slice(0);
        btns.push((
          <span
            id="clear-mobile-search-btn"
            style={{ fontSize: 12, display: 'none', width: 'auto' }}
            onClick={() => {
              clearInput();
              if (typeof onClear === 'function') {
                onClear();
              }
              jQuery('#clear-mobile-search-btn').hide();
            }}
          >
            {messages[language]?.cancel || 'Cancel'}
          </span>
        ));
        setButtons(btns);
      }
      setRenderedClearButton(true);
    } else {
      setButtons(rightButtons);
    }
  }, []);

  const clearInput = () => {
    setQuery('');
  };

  const onInputChangeHandler = ({ target: { value } }) => {
    if (value !== query) {
      setQuery(value);
    }
  };

  const getImage = (suggestion) => {
    // FIXME For now, just cigar results - handle others
    return Cigar.getBandImage(suggestion);
  };

  const renderRightButtons = () => {
    console.log('Rendering right buttons...');
    // TODO Can we pass aria-label in RightButton and use it here? RightButton.props.ariaLabel
    return buttons.map((button) => (
      <IconButton
        aria-label=""
        edge="end"
        color="inherit"
        style={{
          minWidth: 44,
          width: (button.props.style && button.props.style.width) || 44,
          padding: 6,
          float: 'right',
          marginRight: 5,
          height: 40,
        }}
      >
        { button }
      </IconButton>
    ));
  };

  const handleKeyPress = ({ key, target: { value } }) => {
    if (key === 'Enter' && typeof props.onEnter === 'function') {
      props.onEnter(value);
    }
  };

  const searchAutocomplete = (value) => {
    if (typeof onQueryChange === 'function') {
      onQueryChange(value);
      // TODO Do we need to track this? I'd like the user to have a search history but be able to clear it, like on FB
      // if (window.analytics) {
      //   window.analytics.track('Search Query', value);
      // }
    }
  };

  const fetchSearchResults = (value) => {
    if (value.length < 5) {
      throttle(500, searchAutocomplete(value));
    } else {
      debounce(500, searchAutocomplete(value));
    }
  };

  // Patch to fix issue in BXPR-154
  let iosStyles = {};
  if (isIOS) { // FIXME iPad? Otherwise delete as this searchbar is for desktop only
    iosStyles = {
      display: 'block',
      height: 56,
      paddingTop: 1,
    };
  }
  return (
    <div
      id={props.id || 'main-search-bar'}
      className={window.location.pathname.indexOf('search') !== -1 ? 'main-search-bar-no-suggest' : ''}
      style={{ ...props.style, ...iosStyles }}
    >
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Icon name="search" />
        </div>
        {/* https://blog.bitsrc.io/how-to-build-an-autocomplete-widget-with-react-and-elastic-search-dd4f846f784 */}
        <Autocomplete
          id={props.inputId || 'mobile-search-input'}
          placeholder={props.placeholder}
          suggestions={(suggestions || []).slice(0, 8).concat({ full_name: query, search: true })}
          onSuggestionsFetchRequested={({ value }) => {
            console.log('Fetching new results...');
            fetchSearchResults(value);
          }}
          // onSuggestionsClearRequested={setSuggestions([])}
          onSuggestionSelected={(event, { suggestion }) => {
            if (suggestion.search) {
              props.history.push(`/search?q=${query}`);
              // fetchSearchResults(query);
            } else {
              console.log(suggestion);
              props.history.push(`/cigars/${suggestion.hash_id}`);
            }
          }}
          getSuggestionValue={(suggestion) => suggestion.full_name}
          renderSuggestion={(suggestion) => (
            <div style={{ cursor: 'pointer', padding: 10, display: 'flex' }}>
              {suggestion.search && (
                <span
                  style={{
                    backgroundColor: '#d6c290',
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    color: 'white',
                    marginRight: 10,
                    textAlign: 'center',
                    paddingTop: 7,
                  }}
                >
                  <Icon name="search" />
                </span>
              )}
              {!suggestion.search && <Avatar className="avatar-contained" src={getImage(suggestion)} style={{ width: 40, marginRight: 10 }}>{suggestion.full_name.charAt(0)}</Avatar>}
              <span style={{ flex: 1, alignSelf: 'center' }}>{suggestion.full_name}</span>
            </div>
          )}
          inputProps={{
            placeholder: props.placeholder,
            value: query || '',
            onChange: onInputChangeHandler,
            'aria-label': 'search',
            onKeyPress: handleKeyPress,
            onFocus: () => {
              jQuery('#clear-mobile-search-btn').show();
              if (typeof props.onFocus === 'function') {
                props.onFocus();
              }
            },
            onBlur: () => {
              setTimeout(() => {
                jQuery('#clear-mobile-search-btn').hide();
              }, 100);
            },
          }}
        />
        { rightButtons && renderRightButtons() }
      </div>
    </div>
  );
});

MainSearchBar.propTypes = {
  onQueryChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showClearButton: PropTypes.bool,
  onClear: PropTypes.func,
  rightButtons: PropTypes.array,
};

MainSearchBar.defaultProps = {
  placeholder: 'Search...',
  showClearButton: false,
  onClear: null,
  rightButtons: [],
};

export default withRouter(MainSearchBar);
