import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactDOM from 'react-dom';
import IosAlert from '../IosModal';
import './style.scss';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d6c290',
    },
    secondary: {
      main: '#17191d',
    },
  },
});

// SEE https://github.com/GA-MO/react-confirm-alert/blob/master/src/index.js
// It would be nice to be able to show these by calling a function with options rather than rendering
// Should do the same for toasts and similar types of display elements that just need triggered
function ModalDialog(props) {
  if (isIOS) {
    const items = [{
      label: 'Dismiss',
      onClick: props.onClose || ModalDialog.close,
    }];
    return (
      <IosAlert
        show={props.show}
        title={props.title}
        content={props.message}
        buttons={props.buttons || items}
        onDismiss={props.onClose || ModalDialog.close}
      />
    );
  }

  if (isAndroid) {
    const actions = props.buttons ? props.buttons.map((button) => (
      <Button
        color={button.color || 'primary'}
        onClick={button.onClick}
      >
        {button.label}
      </Button>
    )) : [
      <Button
        color={'default'}
        onClick={props.onClose || ModalDialog.close}
      >
        {'Dismiss'}
      </Button>,
    ];
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          title={props.title}
          actions={actions}
          modal={false}
          open={props.show}
          onClose={props.onClose || ModalDialog.close}
        >
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {actions}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }

  // FIXME For some reason, sweet alert won't show
  // return (
  //   <SweetAlert
  //     show={props.show}
  //     title={props.title}
  //     text={props.message}
  //     type={props.type || 'info'}
  //     showCancelButton
  //     onConfirm={props.onConfirm}
  //     onCancel={props.onClose || ModalDialog.close}
  //     // TODO Custom buttons / actions?
  //     onOutsideClick={props.onClose || ModalDialog.close}
  //   />
  // );
  return (
    <Modal isOpen={props.show} toggle={props.onClose || ModalDialog.close}>
      <ModalHeader toggle={props.onClose || ModalDialog.close}>{props.title}</ModalHeader>
      <ModalBody>{props.message}</ModalBody>
      <ModalFooter>
        <ThemeProvider theme={theme}>
          {props.buttons && props.buttons.length > 0 ? (
            props.buttons.map((button) => (
              <Button color={button.color || 'secondary'} variant={button.variant || 'text'} onClick={button.onClick}>{button.label}</Button>
            ))
          ) : (
            <Button color={button.color || 'secondary'} onClick={props.onClose || ModalDialog.close}>Cancel</Button>
          )}
        </ThemeProvider>
      </ModalFooter>
    </Modal>
  );
}

ModalDialog.show = (props) => {
  let divTarget = document.getElementById('react-modal-dialog');
  if (divTarget) {
    // Rerender - the mounted ModalDialog
    ReactDOM.render(<ModalDialog show {...props} />, divTarget);
  } else {
    // Mount the ModalDialog component
    // FIXME Something to add to prevent the back divs from scrolling?
    // document.body.children[0].classList.add('react-confirm-alert-blur');
    divTarget = document.createElement('div');
    divTarget.id = 'react-modal-dialog';
    document.body.appendChild(divTarget);
    ReactDOM.render(<ModalDialog show {...props} />, divTarget);
  }
};

ModalDialog.close = () => {
  const target = document.getElementById('react-modal-dialog');
  if (target) {
    ReactDOM.unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
};

export default ModalDialog;
