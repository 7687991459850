import React from 'react';
import { isIOS } from 'mobile-device-detect';
import Picker from 'react-scrollable-picker';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

function MobilePicker(props) {
  const renderPicker = () => {
    if (isIOS) {
      return (
        <Picker
          optionGroups={props.optionGroups}
          valueGroups={props.valueGroups}
          onChange={props.handleChange}
        />
      );
    }
    // FIXME Will this work? Does this allow the columns to scroll independently?
    return (
      <Row>
        {Object.keys(props.optionGroups).map((key) => {
          const items = props.optionGroups[key];
          return (
            <Col style={{ maxHeight: 320 }}>
              <List>
                {items.map((item) => (
                  <ListItem
                    onClick={() => {
                      if (typeof props.handleChange === 'function') {
                        props.handleChange(key, item.value);
                      }
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
              </List>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <Drawer
      anchor="bottom"
      open={props.open}
      onClick={props.toggle}
      onRequestClose={props.toggle}
    >
      <div
        role="presentation"
        onClick={props.toggle}
        onKeyDown={props.toggle}
      >
        {props.title && (
          <div
            style={{
              textAlign: 'center',
              padding: 10,
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {props.title}
          </div>
        )}
        {props.message && (
          <div
            style={{
              textAlign: 'center',
              padding: 10,
              fontSize: 12,
            }}
          >
            {props.message}
          </div>
        )}
        {renderPicker()}
      </div>
    </Drawer>
  );
}

MobilePicker.propTypes = {
  optionGroups: PropTypes.object.isRequired,
  valueGroups: PropTypes.object.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  handleChange: PropTypes.func,
  toggle: PropTypes.func,
  open: PropTypes.bool,
};

MobilePicker.defaultProps = {
  title: null,
  message: null,
  open: false,
  handleChange: () => {},
  toggle: () => {},
};

export default MobilePicker;
