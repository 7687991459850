import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { isAndroid, isMobile } from 'mobile-device-detect';
import { Button } from 'reactstrap';
import useInstallPrompt from '../../utils/hooks/useInstallPrompt';
import { SettingsStore } from '../../stores';
import { usePersistedState } from '../../utils/usePersistedState';

let installPromptEvent;

const urlParams = new URLSearchParams(window.location.search);
const standalone = urlParams.get('standalone') === 'true';
const isInStandaloneMode = window.Capacitor.isNative || standalone || window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;

// https://blog.wick.technology/pwa-install-prompt/
// https://medium.com/swlh/a-simple-react-hook-to-prompt-ios-users-to-install-your-wonderful-pwa-4cc06e7f31fa
function InstallPromptHandler({ history }) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const [redirectInstall, setRedirectInstall] = useState(false);
  const [showPrompt, setShowPrompt] = usePersistedState('show_install_prompt', true); // FIXME Reset after 2 weeks or so
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useInstallPrompt('installPromptedAt');

  useEffect(() => {
    if (isAndroid) {
      const beforeInstallPromptHandler = (event) => {
        event.preventDefault();
        installPromptEvent = event;
      };
      window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
      return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    }
  }, []);

  useEffect(() => {
    // FIXME We should use something like global state to determine when to show based on events rather than just a simple
    //  check for how long the user was in the app
    // setTimeout(() => {
    //   // FIXME This is still showing for the installed/standalone app - I'd bet it's related to the userShouldBePromptedToInstall flag
    //   if (!isInStandaloneMode && userShouldBePromptedToInstall) {
    //     handleUserSeeingInstallPrompt();
    //     setRedirectInstall(true);
    //   }
    // }, 15000);
  }, [userShouldBePromptedToInstall]);

  // FIXME Switch to userShouldBePromptedToInstall instead of showPrompt - remove showPrompt completely
  // if (isMobile && !isInStandaloneMode && userShouldBePromptedToInstall) {
  if (isMobile && !isInStandaloneMode && showPrompt === true && window.location.pathname !== 'install') {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          backgroundColor: darkMode ? '#d5c196' : '#2b2b2b', // FIXME What color in dark mode?
          padding: 20,
          boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 5px 3px',
          color: darkMode ? '#2b2b2b' : '#e0e0e0',
          zIndex: 1001,
        }}
      >
        <h4 style={{ color: darkMode ? '#2b2b2b' : '#e0e0e0', fontWeight: 700 }}>Install Cigars Near Me for quick access</h4>
        <p style={{ marginBottom: 20 }}>Add Cigars Near Me to your homescreen to access it as a native app. Find local shops & lounges, check in, and more.</p>
        <div>
          <Button
            outline
            onClick={() => {
              setShowPrompt(false);
              // TODO Set a timeout period - Google uses 30 days, but I think a week or 2 is fine max
            }}
            style={{ color: darkMode ? '#2b2b2b' : '#efefef', border: '1px solid #888' }}
          >
            {'Not Now'}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (installPromptEvent) {
                installPromptEvent.prompt();
                installPromptEvent.userChoice.then((choice) => {
                  if (choice.outcome !== 'accepted') {
                    handleUserSeeingInstallPrompt();
                  }
                  setShowPrompt(false);
                });
              } else {
                history.push('/install');
              }
            }}
            style={{ float: 'right', color: darkMode ? '#dfdfdf' : '#2b2b2b', backgroundColor: darkMode ? '#2b2b2b' : '#d5c196' }}
          >
            {'Install'}
          </Button>
        </div>
      </div>
    );
  }
  // TODO May want to be more aggressive at some point and redirect them straight to install
  if (redirectInstall) {
    return <Redirect to="/install" />;
  }
  return null;
}

export default withRouter(InstallPromptHandler);
