import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'mobile-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { throttle, debounce } from 'throttle-debounce';
import Icon from '../Icon';
import { language, messages } from '../../utils/localeUtils';
import './style.scss';

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    margin: '8px 20px',
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    // FIXME These cause problems is dark mode when installed natively on Android
    backgroundColor: darken(theme.palette.common.white, 0.10),
    '&:hover': {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },
    '&:focus': {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    // height: '100%',
    // position: 'absolute',
    height: 42,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    flex: 1,
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const MobileSearchBar = forwardRef((props, ref) => {
  const classes = useStyles();
  const [renderedClearButton, setRenderedClearButton] = useState(false);
  const [buttons, setButtons] = useState([]);
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    clearQuery() {
      clearInput();
    },
  }));

  const [query, setQuery] = useState(null);
  const { rightButtons, onQueryChange, showClearButton, onClear } = props;

  useEffect(() => {
    if (showClearButton) {
      if (!renderedClearButton || props.forceRerender) {
        const btns = rightButtons.slice(0);
        btns.push((
          <span
            id="clear-mobile-search-btn"
            style={{ fontSize: 12, display: 'none', width: 'auto' }}
            onClick={() => {
              clearInput();
              if (typeof onClear === 'function') {
                onClear();
              }
              jQuery('#clear-mobile-search-btn').hide();
            }}
          >
            {messages[language]?.cancel || 'Cancel'}
          </span>
        ));
        setButtons(btns);
      }
      setRenderedClearButton(true);
    } else {
      setButtons(rightButtons);
    }
  }, [rightButtons]);

  useEffect(() => {
    if (typeof props.inputRef === 'function') {
      console.log('Calling inputRef from MobileSearchBar');
      if (inputRef) {
        props.inputRef(inputRef);
      }
    }
  }, [inputRef]);

  const searchAutocomplete = (value) => {
    if (typeof onQueryChange === 'function') {
      onQueryChange(value);
      // TODO Do we need to track this? I'd like the user to have a search history but be able to clear it, like on FB
      // if (window.analytics) {
      //   window.analytics.track('Search Query', value);
      // }
    }
  };

  const fetchSearchResults = (value) => {
    console.log(`Getting search result for: ${value}`);
    if (value.length < 5) {
      throttle(500, searchAutocomplete(value));
    } else {
      debounce(500, searchAutocomplete(value));
    }
  };

  const clearInput = () => {
    setQuery('');
  };

  const onInputChangeHandler = ({ target: { value } }) => {
    if (value !== query) {
      setQuery(value);

      console.log('Calling `onQueryChange`');
      fetchSearchResults(value);
    }
  };

  const renderRightButtons = () => {
    console.log('Rendering right buttons...');
    // TODO Can we pass aria-label in RightButton and use it here? RightButton.props.ariaLabel
    return buttons.map((button) => (
      <IconButton
        aria-label=""
        edge="end"
        color="inherit"
        style={{
          minWidth: 44,
          width: (button.props.style && button.props.style.width) || 44,
          padding: 6,
          float: 'right',
          marginRight: 5,
          height: 40,
        }}
      >
        { button }
      </IconButton>
    ));
  };

  // Patch to fix issue in BXPR-154
  let iosStyles = {};
  if (isIOS) {
    iosStyles = {
      display: 'block',
      height: 56,
      paddingTop: 1,
    };
  }
  return (
    <AppBar
      id={props.id || 'mobile-search-bar'}
      position="static"
      color="white"
      style={{ ...props.style, ...iosStyles }}
    >
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Icon name="search" style={{ zIndex: 1 }} />
        </div>
        <InputBase
          id={props.inputId || 'mobile-search-input'}
          inputRef={props.inputRef}
          autoComplete="off"
          placeholder={props.placeholder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={query}
          inputProps={{ 'aria-label': 'search' }}
          onChange={onInputChangeHandler}
          onFocus={() => {
            jQuery('#clear-mobile-search-btn').show();
            if (typeof props.onFocus === 'function') {
              props.onFocus();
            }
          }}
          // onBlur={() => {
          //   console.log('Should hide keyboard...');
          //   // setTimeout(() => {
          //   //   jQuery('#clear-mobile-search-btn').hide()
          //   // }, 100);
          // }}
        />
        { rightButtons && renderRightButtons() }
      </div>
    </AppBar>
  );
});

MobileSearchBar.propTypes = {
  onQueryChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showClearButton: PropTypes.bool,
  onClear: PropTypes.func,
  rightButtons: PropTypes.array,
};

MobileSearchBar.defaultProps = {
  placeholder: 'Search...',
  showClearButton: false,
  onClear: null,
  rightButtons: [],
};

export default MobileSearchBar;
